import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { es } from "ui-components";

const HTML_TAGS_MAINTAINED_BY_TRANS = ["br", "strong", "i", "p", "em", "span", "ul", "li"];

const i18nOptions = {
  fallbackLng: "es",
  resources: { es },
  interpolation: { escapeValue: false },
  react: {
    transKeepBasicHtmlNodesFor: HTML_TAGS_MAINTAINED_BY_TRANS,
  },
};

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(i18nOptions);

export default i18n;
