import { stringify } from 'query-string'

const useFetch = () => {
  const baseUrl = process.env.REACT_APP_API

  const getHeaders = () => {
    return {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    }
  }

  const responseHandler = async response => {
    const res = await response.json()
    if (response.status.toString()[0] !== '2') {
      throw res
    } else {
      return res.payload
    }
  }

  const get = async ({ endpoint, params }) => {
    const url = `${baseUrl}/${endpoint}${params ? `?${stringify(params)}` : ''}`
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: getHeaders()
      })
      const parsedResponse = responseHandler(response)
      return parsedResponse
    } catch (e) {
      return e
    }
  }

  const post = async ({ endpoint, body }) => {
    const url = `${baseUrl}/${endpoint}`
    try {
      const response = await fetch(url, {
        body: JSON.stringify(body),
        method: 'POST',
        headers: getHeaders()
      })
      const parsedResponse = await responseHandler(response)
      return parsedResponse
    } catch (e) {
      throw e
    }
  }

  const postFile = async ({ endpoint, body }) => {
    const url = `${baseUrl}/${endpoint}`
    try {
      const response = await fetch(url, {
        body: body,
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.nexusBackendAccessToken}`
        }
      })
      const parsedResponse = await responseHandler(response)
      return parsedResponse
    } catch (e) {
      throw e
    }
  }

  const put = async ({ endpoint, id, body }) => {
    const url = `${baseUrl}/${endpoint}/${id}`
    try {
      const response = await fetch(url, {
        body: JSON.stringify(body),
        method: 'PUT',
        headers: getHeaders()
      })
      const parsedResponse = await responseHandler(response)
      return parsedResponse
    } catch (e) {
      throw e
    }
  }

  const patch = async ({ endpoint, id, body }) => {
    const url = `${baseUrl}/${endpoint}/${id}`
    try {
      const response = await fetch(url, {
        body: JSON.stringify(body),
        method: 'PATCH',
        headers: getHeaders()
      })
      const parsedResponse = await responseHandler(response)
      return parsedResponse
    } catch (e) {
      throw e
    }
  }

  const patchImage = async ({ endpoint, id, body }) => {
    const url = `${baseUrl}/${endpoint}/${id}`
    try {
      const response = await fetch(url, {
        body: body,
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${localStorage.nexusBackendAccessToken}`
        }
      })
      const parsedResponse = await responseHandler(response)
      return parsedResponse
    } catch (e) {
      throw e
    }
  }

  return {
    get,
    post,
    put,
    patch,
    patchImage,
    postFile
  }
}

export default useFetch
