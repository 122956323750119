import mqtt from 'mqtt'

const MqttService = (function() {
  'use strict'
  var instance //prevent modification of "instance" variable

  function Singleton({ host, token, ttl, clientId, username, wsport }) {
    if (instance) {
      return instance
    } else {
      const url = `wss://${host}:${wsport}/mqtt`
      const client = mqtt.connect(url, {
        password: token,
        keepalive: ttl,
        clientId,
        username,
        reconnectPeriod: 2000
      })
      client.on('connect', function() {})

      instance = client

      return client
    }
    //Singleton initialization code
  }
  // Instance accessor
  Singleton.getInstance = function(data) {
    return instance || new Singleton(data)
  }

  return Singleton
})()

export default MqttService
