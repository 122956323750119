import { useTranslation } from "react-i18next";

const Welcome = ({ conf, nextStep }) => {
  const { t } = useTranslation();

  return (
    <div className="WidgetPage flex column start">
      <div className="WidgetPage__header">
        <div className="WidgetPage__header__logo">
          <img src={conf.logo} alt="" />
        </div>
        <div className="WidgetPage__header__photo" style={{ backgroundImage: `url("${conf.firstPageImage}")` }}></div>
      </div>

      <div className="WidgetPage__content flex column between">
        <div className="contentTop flex column start">
          <div className="title">{t("Welcome to the __companyName tracking platform", { companyName: conf.companyName })}</div>
          <div className="text">{t("Follow your roadside assistance service in real time by clicking on the continue button")}</div>
        </div>
        <div className="contentBtn flex column end">
          <button className="Widget__button" onClick={nextStep}>
            {t("continue")}
          </button>
        </div>
      </div>

    </div>
  );
};

export default Welcome;
