import { useTranslation } from "react-i18next";

const ErrorPage = ({ conf }) => {
  const { t } = useTranslation();

  return (
    <div className="WidgetPage flex column start">
      <div className="WidgetPage__header">
        <div className="WidgetPage__header__logo">
          <img src={conf.logo} alt="" />
        </div>
        <div className="WidgetPage__header__photo" style={{ backgroundImage: `url("${conf.cancelledPageImage}")` }}></div>
      </div>

      <div className="WidgetPage__content flex column between">
        <div className="contentTop flex column start">
          <img src="assets/icons/error.svg" alt="" />
          <div className="title cancelled">{t("We are sorry It has not been possible to show the tracking of your service")}</div>
          <div className="text">{t("Reload the page or contact your company")}</div>
        </div>
      </div>

    </div>
  );
};

export default ErrorPage;
