
import { useState, useEffect } from "react";

import useFetch from "./utils/hooks/useFetch";

import Welcome from "./pages/Welcome";
import MapView from "./pages/Map";
import Thanks from "./pages/Thanks";
import Cancelled from "./pages/Cancelled";

import ErrorPage from "./pages/ErrorPage";

import "./styles/bootstrap.scss";
import "./styles/base.scss";
import "./styles/app.scss";
import "ui-components/dist/index.css";

import "./styles.scss";

const App = () => {

  const [step, setStep] = useState(1);

  const [service, setService] = useState(null);
  const [skinConf, setSkinConf] = useState(null);

  const { get } = useFetch();

  const fetchService = async () => {
    const serviceCode = window.location.pathname.split("/")[1];

    try {
      const response = await get({ endpoint: `widget/${serviceCode}` });

      if (response.widget.status === "pending") {
        setStep(1);
      } else if (response.widget.status === "active") {
        switch (response.currentStatus) {
          case "COMPLETED":
          case "FINISHED":
            setStep(3);
            break;
          case "CANCELED":
            setStep(-1);
            break;
          default:
            setStep(2);
            break;
        }
      }

      setService(response);

      setSkinConf({
        logo: response?.widget?.logo || "assets/images/nexusLogo.jpg",
        companyName: response?.widget?.centerName || "Nexus-365",
        firstPageImage: "assets/images/firstPageImage.jpg",
        lastPageImage: "assets/images/lastPageImage.jpg",
        cancelledPageImage: "assets/images/cancelledPageImage.jpg",
        color: response?.widget?.defaultColor || "#ec754a",
        fontTitle: "Open Sans",
        fontText: "Open Sans",
      });

    } catch (err) {

      setService("canceled");
      setSkinConf({
        logo: "assets/images/nexusLogo.jpg",
        companyName: "Nexus-365",
        firstPageImage: "assets/images/firstPageImage.jpg",
        lastPageImage: "assets/images/lastPageImage.jpg",
        cancelledPageImage: "assets/images/cancelledPageImage.jpg",
        color: "#ec754a",
        fontTitle: "Open Sans",
        fontText: "Open Sans",
      });
      setStep(-2);
    }
  }

  const initSkinConf = () => {
    setTimeout(() => {
      const root = document.documentElement;
      root.style.setProperty("--main-color", skinConf.color);
      root.style.setProperty("--fontTitle", skinConf["fontTitle"]);
      root.style.setProperty("--fontText", skinConf["fontText"]);
    }, 10);
  };

  useEffect(() => {
    document.addEventListener('touchmove', function(e) {
      e.preventDefault();
    }, { passive: false });
    const getServiceDetail = async () => {
      const response = await fetchService();
      return response;
    }
    setService( getServiceDetail());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!service) return;
    initSkinConf();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [skinConf]);

  return (
    ((service && skinConf) && (
      <div className="Widget">
        {step === -2 && <ErrorPage conf={skinConf} />}
        {step === -1 && <Cancelled conf={skinConf} />}
        {step === 1 && (
          <Welcome
            conf={skinConf}
            nextStep={() =>
              service.currentStatus === "FINISHED" || service.currentStatus === "COMPLETED" ? setStep(2) : setStep(2)
            }
          />
        )}
        {step === 2 && (
          <MapView
            conf={skinConf}
            nextStep={() => setStep(3)}
            service={service}
            reloadService={fetchService}
            cancelService={() => setStep(-1)}
          />
        )}
        {step === 3 && <Thanks conf={skinConf} />}
      </div>
    )) ||
    false
  );

};

export default App;

