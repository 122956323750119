import { useState, useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";

import MqttService from "../../services/MqttService";
import useFunction from "../../utils/hooks/useFunction";

import { lightFormat } from "date-fns";
import { computeHeading } from "spherical-geometry-js";

import "./styles.scss";
import mapStyles from "./mapStyles";

const google = (window.google = window.google ? window.google : {});

const ProgressAnimation = () => (
  <div id="squaresWaveG">
    <div id="squaresWaveG_1" className="squaresWaveG"></div>
    <div id="squaresWaveG_2" className="squaresWaveG"></div>
    <div id="squaresWaveG_3" className="squaresWaveG"></div>
    <div id="squaresWaveG_4" className="squaresWaveG"></div>
    <div id="squaresWaveG_5" className="squaresWaveG"></div>
    <div id="squaresWaveG_6" className="squaresWaveG"></div>
    <div id="squaresWaveG_7" className="squaresWaveG"></div>
    <div id="squaresWaveG_8" className="squaresWaveG"></div>
  </div>
);

const MapView = ({ service, nextStep, reloadService, cancelService }) => {
  const { t } = useTranslation();

  const mapRef = useRef();
  const mapElementRef = useRef();
  const clientRef = useRef();
  const craneRef = useRef();
  const pickupRef = useRef();
  const deliveryRef = useRef();
  const [panelExpanded, setPanelExpanded] = useState(false);

  const [isLocated, setIsLocated] = useState((
    service.currentStatus === "LOCATED" ||
    service.currentStatus === "INRIS" ||
    service.currentStatus === "LOADED" ||
    service.currentStatus === "COMPLETED") ? true : false
  );

  const [isLoaded, setIsLoaded] = useState(
    service.currentStatus === "LOADED" ||
    service.currentStatus === "COMPLETED"
  );

  const [serviceStatus, setServiceStatus] = useState(service.currentStatus);
  const baseUrl = process.env.REACT_APP_API;
  const ICON_SIZE = 64;

  const trafficLayer = new google.maps.TrafficLayer();

  const initMqtt = () => {
    const client = new MqttService({
      ...service.widget.mqtt,
      username: service._id,
      clientId: service.widget.clientId,
      token: service.widget.password,
    });
    const resourceTopic = `nexus/${service.widget.region}/customers/${service.request.customer.id}/${service._id}/#`;
    client.subscribe(resourceTopic);
    client.on("message", handleMessage);
    clientRef.current = client;
  };

  const handleMessage = useFunction((topic, message) => {
    const msg = JSON.parse(message.toString());
    if (msg.type === "location" && msg.data) {
      const latLng = { lat: msg.data.coordinates[0], lng: msg.data.coordinates[1] };
      moveCrane(latLng);
    }
    if (msg.type === "eventTrack" && msg.data) {
      handleEventAction(msg.data);
    }
  });

  const resourceUrl = resource => {
    //return "https://s3.eu-west-1.amazonaws.com/dev.store.nexus/statics/images/medium_type_map_icons/Platform_marker.svg";
    return service.widget.imageMapUrl;
  };

  /*
  const resourceUrl = resource => {
    const mediumTypeId = resource.request.mediumType._id;
    const driverId = resource.execution.provider.driver._id;
    const mediumId = resource.execution.provider.medium._id;
    const url = `${baseUrl}/public/svgMap/${mediumTypeId}/${driverId}:${mediumId}.svg`;
    return url;
  };
  */

  const rotateMarkerImage = (resource, rotationAngle) => {
    let toggle = document.querySelector('img[src="' + resourceUrl(resource) + '"]');
    toggle.style.transform = "rotate(" + rotationAngle + "deg)";
  };

  const handleEventAction = data => {
    setServiceStatus(data.event.type);
    switch (data.event.type) {
      case "locate":
        setIsLocated(true);
        setServiceStatus("LOCATED");
        break;
      case "risStart":
        setServiceStatus("INRIS");
        break;
      case "addressChange":
        reloadService();
        break;
      case "load":
        pickupRef.current?.setMap(null);
        goToBounds(true);
        setIsLoaded(true);
        setServiceStatus("LOADED");
        break;
      case "complete":
        nextStep();
        break;
      case "finish":
        nextStep();
        break;
      case "canceled":
        cancelService();
        break;
      default:
        break;
    }
  };

  const clearMarkers = () => {
    deliveryRef.current?.setMap(null);
    pickupRef.current?.setMap(null);
    craneRef.current?.setMap(null);
  };

  const initMarkers = () => {
    clearMarkers();
    if (service.currentStatus !== "LOADED") {
      pickupRef.current = new google.maps.Marker({
        position: {
          lat: service.request.pickup.address.coordinates[0],
          lng: service.request.pickup.address.coordinates[1],
        },
        map: mapRef.current,
        icon: "assets/icons/marker-pickup.svg",
      });
    }
    if (service.execution.delivery?.address) {
      deliveryRef.current = new google.maps.Marker({
        position: {
          lat: service.execution.delivery.address.coordinates[0],
          lng: service.execution.delivery.address.coordinates[1],
        },
        map: mapRef.current,
        icon: "assets/icons/marker-delivery.svg",
      });
    }
    craneRef.current = new google.maps.Marker({
      position: {
        lat: service.widget.lastLocation.coordinates[0],
        lng: service.widget.lastLocation.coordinates[1],
      },
      map: mapRef.current,
      icon: {
        url: resourceUrl(service),
        anchor: new google.maps.Point(ICON_SIZE / 2, ICON_SIZE / 2),
        scaledSize: new google.maps.Size(ICON_SIZE, ICON_SIZE), // scaled size
        origin: new google.maps.Point(0, 0), // origin
      },
    });
  };

  const goToBounds = onlyDelivery => {
    var bounds = new google.maps.LatLngBounds();
    if ((!onlyDelivery || service.currentStatus !== "LOADED") && service.execution.delivery?.address) {
      bounds.extend({
        lat: service.execution.delivery.address.coordinates[0],
        lng: service.execution.delivery.address.coordinates[1],
      });
    }
    bounds.extend({
      lat: service.request.pickup.address.coordinates[0],
      lng: service.request.pickup.address.coordinates[1],
    });
    bounds.extend({
      lat: service.widget.lastLocation.coordinates[0],
      lng: service.widget.lastLocation.coordinates[1],
    });
    if (!bounds.isEmpty()) {
      if (bounds.getNorthEast().equals(bounds.getSouthWest())) {
        const extendPoint1 = new google.maps.LatLng(
          bounds.getNorthEast().lat() + 0.01,
          bounds.getNorthEast().lng() + 0.01
        );
        const extendPoint2 = new google.maps.LatLng(
          bounds.getNorthEast().lat() - 0.01,
          bounds.getNorthEast().lng() - 0.01
        );
        bounds.extend(extendPoint1);
        bounds.extend(extendPoint2);
      }
      mapRef.current.fitBounds(bounds);
    }
  };

  const moveCrane = location => {
    const prevPosn = craneRef.current.getPosition();
    const rotation = computeHeading(prevPosn, location);
    if (rotation !== 0) {
      rotateMarkerImage(service, rotation);
    }
    craneRef.current.setPosition(location);
  };

  const getAssistenceMessage = () => {
    switch (serviceStatus) {
      case "LOCATED":
        return <div>{t("Assistance has arrived at pickup")}</div>;

      case "INRIS":
        return <div>{t("We are trying to repair your vehicle")}</div>;

      case "LOADED":
        return (
          <>
            <div>{t("Your vehicle is being moved")}</div>
            <div className="bold">
              {t("estimatedTime")}{" "}
              {lightFormat(
                new Date(service.widget.routes[1] ? service.widget.routes[1].eta : service.widget.routes[0].eta),
                "HH:mm"
              )}
            </div>
          </>
        );
      case "COMPLETED":
        return (
          <>
            <div>{t("Support has ended")}</div>
          </>
        );
      default:
        return (
          <>
            <div>{t("Assistance is on the way")}</div>
            <div className="bold">
              {t("estimatedTime")} {lightFormat(new Date(service.widget.routes[0].eta), "HH:mm")}
            </div>
          </>
        );
    }
  };

  useEffect(() => {
    if (!service) return;
    if (!mapRef.current) {
      initMqtt();
      mapRef.current = new google.maps.Map(mapElementRef.current, {
        center: { lat: 40.4482071, lng: -3.7980438 },
        zoom: 8,
        zoomControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        fullscreenControl: false,
        options: { styles: mapStyles },
      });
    }
    trafficLayer.setMap(mapRef.current);
    initMarkers();
    goToBounds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [service]);

  return (
    <div className={`MapView flex column ${panelExpanded ? "MapView--panel-expanded" : ""}`}>
      <div ref={mapElementRef} className="MapView__map" />

      <div className="MapView__infobox flex column h-center nowrap">
        <div className="MapView__infobox__data">{getAssistenceMessage()}</div>
      </div>

      <div className="MapView__panel" onClick={() => setPanelExpanded(!panelExpanded)}>
        <div className="MapView__panel__trigger flex v-center h-center">
          <img src={`assets/icons/arrow-${panelExpanded ? "bot" : "top"}.svg`} alt="" />
        </div>

        <div className="MapView__data">
          <div className="MapView__progress flex v-center h-center">





            <div className="MapView__progress__icon MapView__progress__icon--completed flex column v-center h-center">
              <img src="assets/icons/start.svg" alt="" />
              <div className="MapView__progress__icon__text">{t("start")}</div>
            </div>

            {(!isLocated && <ProgressAnimation />) || (
              <div className="MapView__progress__bar MapView__progress__bar--completed" />
            )}




            <div
              className={`MapView__progress__icon
                ${isLocated || serviceStatus === "COMPLETED"
                  ? "MapView__progress__icon--completed"
                  : "MapView__progress__icon--next"
                }
                flex column v-center h-center`}
            >
              <img src="assets/icons/pickup.svg" alt="" />
              <div className="MapView__progress__icon__text">{t("pickup")}</div>
            </div>

            {(isLoaded && serviceStatus !== "COMPLETED" && <ProgressAnimation />) || (
              <div
                className={`MapView__progress__bar MapView__progress__bar${serviceStatus === "COMPLETED" ? "--completed" : ""
                  }`}
              />
            )}




            <div
              className={`MapView__progress__icon
                ${isLoaded && serviceStatus !== "COMPLETED"
                  ? "MapView__progress__icon--next"
                  : serviceStatus === "COMPLETED"
                    ? "MapView__progress__icon--completed"
                    : ""
                }
                flex column v-center h-center"
              `}
            >
              <img className="imgDeliveryIcon" src="assets/icons/delivery.svg" alt="" />
              <div className="MapView__progress__icon__text">{t("delivery")}</div>
            </div>






          </div>
        </div>

        <div className="MapView__contact flex between nowrap">
          <div className="MapView__contact__wrapper flex">
            <div className="MapView__contact__avatar">
              {(service.execution.provider.driver.photo && (
                <img src={service.execution.provider.driver.photo} alt="" />
              )) || <img src={"assets/icons/driver.svg"} alt="" />}
            </div>
            <div className="MapView__contact__info">
              <div className="MapView__contact__name font2">
                {service.execution.provider.driver.name} {service.execution.provider.driver.surname}
              </div>
              <div className="MapView__contact__vehicle">
                {service.execution.provider.medium.plate}
                {(service.execution.provider.medium.brand || service.execution.provider.medium.model) && ","}
                {" " + (service.execution.provider.medium.brand || "")}
                {" " + (service.execution.provider.medium.model || "")}
              </div>
              {true && <div className="MapView__contact__vehicle"></div>}
            </div>
          </div>
          {service.execution.provider.driver?.mainContact?.phone && (
            <a
              className="MapView__contact__call flex v-center h-center"
              href={`tel:${service.execution.provider.driver.mainContact.phone}`}
            >
              <img src="assets/icons/phone.svg" alt="" />
            </a>
          )}
        </div>
        <div className={`MapView__addresses ${!panelExpanded ? "display-none" : ""}`}>
          <div className="MapView__addresses__item flex v-center">
            <img src="assets/icons/pickup-dark.svg" alt="" />
            <div>{service.request.pickup.address.fullAddress}</div>
          </div>
          <div className="MapView__addresses__item flex v-center">
            <img src="assets/icons/delivery-dark.svg" alt="" />
            <div>
              {service.execution.delivery?.address
                ? service.execution.delivery.address.fullAddress
                : "Pendiente de definir"}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapView;
