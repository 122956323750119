import React from 'react'

const useFunction = callback => {
  const ref = React.useRef()
  ref.current = callback

  return React.useCallback(function() {
    const callback = ref.current
    if (typeof callback === 'function') {
      return callback.apply(this, arguments)
    }
  }, [])
}

export default useFunction
