import { useTranslation } from "react-i18next";

const Thanks = ({ conf }) => {
  const { t } = useTranslation();
  
  return (
    <div className="WidgetPage flex column start">
      <div className="WidgetPage__header">
        <div className="WidgetPage__header__logo">
          <img src={conf.logo} alt="" />
        </div>
        <div className="WidgetPage__header__photo" style={{ backgroundImage: `url("${conf.lastPageImage}")` }}></div>
      </div>

      <div className="WidgetPage__content flex column between">
        <div className="contentTop flex column start">
          <img src="assets/icons/success.svg" alt="" />
          <div className="title thanks">{t("Support has ended")}</div>
          <div className="text">{t("Thank you very much for your confidence")}.</div>
          <div className="text lastText">{t("We are at your disposal whenever you need it")}.</div>
        </div>
      </div>

    </div>
  );
};

export default Thanks;
